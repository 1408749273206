.brandTitle {
    margin: 80px auto 0 auto;
    text-align: center;
    .title {
        font-size: 40px;
        line-height: 56px;
        color: #333;
        font-weight: 600;
        margin: 0 auto;
    }
    .subtitle {
        font-size: 18px;
        line-height: 25px;
        color: #333;
        margin: 20px auto 0 auto;

    }
}
.infrastructureItem {
    width: 350px;
    height: 276px;
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-left: 24px;
    padding: 40px 30px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .tag {
        width: 126px;
        height: 43px;
        position: absolute;
        top: -12px;
        left: 0;
    }
    img {
        width: 88px;
        height: 88px;
    }
    div {
        margin-top: 17px;
        font-size: 24px;
        color: #333;
        font-weight: 600;
        line-height: 33px;
        position: relative;
        z-index: 1;
        i {
            display: block;
            width: 155px;
            height: 18px;
            background: rgba(44, 212, 113, 0.5);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
    p {
        font-size: 16px;
        color: #383830;
        line-height: 20px;
        margin-top: 16px;
        text-align: left;
    }
}
.partLeft {
    width: 550px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .partTitle {
        width: 550px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;
        color: #FFFFFF;
        text-align: left;
        position: relative;
        z-index: 1;
        p {
            font-size: 150px;
            color: #41CF73;
            line-height: 120px;
            position: absolute;
            bottom: 0;
            z-index: -1;
            font-family: PingFangSC-Regular;
        }
    }
    .partDesc {
        width: 550px;
        font-size: 18px;
        color: #E5FFEE;
        line-height: 24px;
        margin-top: 25px;
        text-align: left;
    }
    .partBottom {
        width: 593px;
        margin-top: 60px;
        text-align: left;
        .partItemWrap {
            display: inline-block;
            vertical-align: top;
            .line {
                display: inline-block;
                width: 0px;
                height: 123px;
                border-right: 1px dashed #4CDF80;
                margin: 0 50px;
            }
            .partItem {
                display: inline-block;
                text-align: left;
                vertical-align: top;
                .partItemTop {
                    text-align: right;
                    .amount {
                        font-size: 70px;
                        line-height: 80px;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                    .unit {
                        font-size: 30px;
                        line-height: 42px;
                        color: #FFFFFF;
                    }
                }
                .desc {
                    font-size: 18px;
                    color: #E5FFEE;
                    line-height: 25px;
                    width: 130px;
                    text-align: left;
                }
            }
        }
    }
}
.salesItemWrap {
    width: 180px;
    height: 240px;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .tagText {
        width: 129px;
        height: 30px;
        background: #2CD471;
        border-radius: 0px 0px 18px 18px;
        margin: 0 auto;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        padding-top: 5px;
        box-sizing: border-box;
    }
    img {
        width: 113px;
        height: 47px;
        margin-top: 33px;
    }
    .title {
        font-size: 22px;
        color: #333;
        font-weight: 600;
        line-height: 30px;
        margin-top: 14px;
    }
    .descList {
        margin-top: 11px;
        .descItem {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .bottomLine {
        width: 100%;
        height: 6px;
        background: #2CD471;
        border-radius: 0px 0px 3px 3px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
