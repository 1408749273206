.nav-bar-wrap{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  .nav-content{
    width: 1200px;
    margin: 0 auto;
    padding: 24px 0px 0px;
    .nav-left{
      width: 160px;
      height: 36px;
      margin-left: 20px;
      background-repeat: no-repeat;
      background-size: 160px 36px;
      margin-top: -6px;
      float: left;
    }
    .nav-right{
      float: right;
      .nav-item-box {
        display: inline-block;
        position: relative;
        padding: 0px 17px;
        box-sizing: border-box;
      .nav-item{
        display: inline-block;
        height: 40px;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        .selectOptions {
          display: none;
          width: 100%;
          position: absolute;
          top: 40px;
          left: 0;
          padding: 15px 0;
          background-color: #ffffff;
          text-align: center;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            padding: 15px 0;
          }
          span:hover {
            color: #46BF6C;
            background-color: rgba(204, 204, 204, 0.1);
          }
        }
        img {
          width: 14px;
          height: 8px;
          margin-left: 4px;
          margin-bottom: 2px;
        }
      }
      .nav-item::after{
        content: "";
        clear: both;
        display: block;
        visibility: hidden;
      }
      .nav-item:hover{
        border-bottom: 4px solid #25C156;
      }
      .nav-item:hover .selectOptions {
        display: block;
      }
      .active{
        font-weight: bold;
        letter-spacing: 0px;
        border-bottom: 4px solid #25C156;
      }
      }
    }
  }
  .nav-content::after{
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
  }
}
.change-phone-wrap{
  height:868px;
  background: #fff;
  padding-top:59px;
  width:1000px;
  margin:0 auto;
  .title-1{
    font-size: 40px;
    color:#333333;
    display: block;
    text-align: left;
    font-weight: 600;
  }
  .title-2{
    font-size: 18px;
    color:#333333;
    display: block;
    text-align: left;
    width:550px;
    margin-top:30px;
  }
  .select-part{
    .select-item{
        width: 643px;
      float: left;
    //   padding:72px 0 60px 0;
      padding: 45px 0 60px 0;
      height:470px;
      .select-text{
          display: inline-block;
          width: 33%;
          height: 100px;
        color:#333333;
        font-size: 24px;
        text-align: left;
        font-weight: 600;
        vertical-align: middle;
          .icon-img{
            width:44px;
            height:44px;
            margin-right:19px;
            vertical-align: middle;
          }
          .text-box{
            display: inline-block;
            vertical-align: middle;
            .short-green-line{
              display:block;
              width:104px;
              height:4px;
              background:#46BF6C;
              margin-top: 10px;
            }
          }
        
      }
      .select-text-after{
        color:#46BF6C;
        font-size: 30px;
        text-align: left;
        font-weight: 600;
        cursor: default;
      }
    }
    .phone-show{
      float: right;
      width: 357px;
      height:700px;
      background: url(../images/phone.png) no-repeat;
      background-size: 357px 700px;
      padding:18px 23px;
      box-sizing: border-box;
      margin-top:-8px;
      opacity: 0;
      -webkit-animation: phoneAppear 1s ease-in 1s forwards;
      -ms-animation: phoneAppear 1s ease-in 1s forwards;
      -moz-animation: phoneAppear 1s ease-in 1s forwards;
      animation: phoneAppear 1s ease-in 1s forwards;
      @-moz-keyframes phoneAppear{
        0%{
          -moz-transform: translate3d(0,0,0);
            opacity: 0;
            display: none;
          }
        100%{
          -moz-transform: translate3d(0,-5%,0);
          opacity: 1;
          display: block;
        }
      }
      @-webkit-keyframes phoneAppear{
        0%{
          -webkit-transform: translate3d(0,0,0);
            opacity: 0;
            display: none;
          }
        100%{
          -webkit-transform: translate3d(0,-5%,0);
          opacity: 1;
          display: block;
        }
      }
      @-moz-keyframes phoneAppear{
        0%{
          -moz-transform: translate3d(0,0,0);
            opacity: 0;
            display: none;
          }
        100%{
          -moz-transform: translate3d(0,-5%,0);
          opacity: 1;
          display: block;
        }
      }
      @-ms-keyframes phoneAppear{
        0%{
          -ms-transform: translate3d(0,0,0);
            opacity: 0;
            display: none;
          }
        100%{
          -ms-transform: translate3d(0,-5%,0);
          opacity: 1;
          display: block;
        }
      }
      @keyframes phoneAppear{
        0%{
          transform: translate3d(0,0,0);
            opacity: 0;
            display: none;
          }
        100%{
          transform: translate3d(0,-5%,0);
          opacity: 1;
          display: block;
        }
      }
      .phone-img{
        // width:100%;
        width:100%;
        height:100%;
        transition: all 0.5s;
        background: url(https://static-o2o.360buyimg.com/activity/pcindex/images/phoned.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .select-part::after{
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
  }
}

.map-wrap{
  width:100%;
//   height:620px;
  height: 640px;
  background: #12C350;
  margin-top: -120px;
  .map-content{
    width:1000px;
    margin:0 auto;
    position: relative;
    .map-title{
      font-size: 40px;
      color:#fff;
      // width: 240px;
      height:56px;
      font-weight: 600;
      position: absolute;
      top:49px;
      left:0;
      z-index: 100;
    }
    .map-desc{
      font-size: 18px;
      color:#fff;
      height:26px;
      position: absolute;
      top:125px;
      left:0;
      text-align: left;
      z-index: 100;
    }
    .data-desc{
      width: 620px;
      height: 200px;
      position: absolute;
      top: 211px;
      left: 0;
      // display: flex;
      // justify-content: space-between;
      z-index: 100;
      .map-item{
        display: inline-block;
        .num{
          color:#fff;
          font-size: 55px;
          margin: 20px 0px 10px;
          line-height: 63px;
        }
        .city{
          color:#fff;
          font-size: 24px;
        }
      }
      .ml{
        margin-left: 52px;
      }
    }
    .map{
      width: 660px;
      height: 495px;
      position: absolute;
      z-index: 100;
      top: 98px;
      right: 0;
      background: url(../images/map.png) no-repeat 0 0;
      background-size: 660px 495px;
      opacity: 0;
      .map-bg{
      }
      .map-tag{
        width: 660px;
        // height: 495px;
        height: 515px;
        // margin-top: -5px;
      }
    }
    .map-showIn{
      opacity: 0;
      -webkit-animation: mapTagInAppear 0.5s ease-in 0.5s forwards;
      -moz-animation: mapTagInAppear 0.5s ease-in 0.5s forwards;
      -ms-animation: mapTagInAppear 0.5s ease-in 0.5s forwards;
      animation: mapTagInAppear 0.5s ease-in 0.5s forwards;
      @-webkit-keyframes mapTagInAppear {
        0%{
          -webkit-transform: translate3d(0, 3%, 0);
          opacity: 0;
          display: none;
        }
        100%{
          -webkit-transform: translate3d(0, 0, 0);
          opacity: 1;
          display: block;
        }
      }
      @-moz-keyframes mapTagInAppear {
        0%{
          -moz-transform: translate3d(0, 3%, 0);
          opacity: 0;
          display: none;
        }
        100%{
          -moz-transform: translate3d(0, 0, 0);
          opacity: 1;
          display: block;
        }
      }
      @-ms-keyframes mapTagInAppear {
        0%{
          -ms-transform: translate3d(0, 3%, 0);
          opacity: 0;
          display: none;
        }
        100%{
          -ms-transform: translate3d(0, 0, 0);
          opacity: 1;
          display: block;
        }
      }
      @keyframes mapTagInAppear {
        0%{
          transform: translate3d(0, 3%, 0);
          opacity: 0;
          display: none;
        }
        100%{
          transform: translate3d(0, 0, 0);
          opacity: 1;
          display: block;
        }
      }
    }
  }
}
.funeng-wrap{
  width:100%;
  height:650px;
  background: #ffffff;
  .funenng-content{
    padding-top:262px;
    width:1000px;
    margin:0 auto;
    position: relative;
    .funeng-title{
      font-size: 40px;
      color:#333333;
      display: block;
      height:40px;
      font-weight: 600;
      position: absolute;
      z-index: 100;
      top:60px;
      left:0;
    }
    .funeng-desc{
      font-size: 18px;
      color:#333333;
      display: block;
      height:52px;
      position: absolute;
      z-index: 100;
      top:130px;
      left:0;
      text-align: left;
    }
    .funeng-list{
      // display: flex;
      // justify-content: space-between;
      height:352px;
      width:100%;
      .funeng-block{
        // display: flex;
        // flex-direction: column;
        // justify-content:space-around;
        // align-items: center;
        display: inline-block;
        width:184px;
        height:300px;
        padding: 18px 10px;
        box-shadow:0px 2px 7px 0px rgba(111,111,111,0.2);
        border-radius:6px;
        box-sizing: border-box;
        text-align: center;
        vertical-align: top;
        .funeng-text1{
          font-size: 20px;
          color:#333333;
          font-weight: 600;
          margin: 15px 0px 13px 0px;
        }
        .funeng-text2{
          font-size: 14px;
          color:#333333;
          text-align: left;
        }
      }
      .ml{
        margin-left: 20px;
      }
      .funeng-block:hover{
        // -webkit-transform: scale(1.1);
        // -ms-transform: scale(1.1);
        // -moz-transform: scale(1.1);
        transform: scale(1.1);
        // -webkit-transform-origin: 50% 50%;
        // -moz-transform-origin: 50% 50%;
        // -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        border: 1px solid #2CD471;
        .funeng-text1{
          color:#2CD471;
          font-weight: 500;
        }
        .funeng-text2{
          color:#2CD471;
          font-weight: 500;
        }
      }
    }
    
  }
}

.ruzhu-wrap{
  width:100%;
  height:203px;
  background: url(../images/ruzhu.png) no-repeat;
  background-size: 100% 203px;
  padding-top:42px;
  box-sizing: border-box;
  .ruzhu-btn{
    width: 320px;
    height:60px;
    display: block;
    margin: 0 auto;
    background: #ffffff;
    color:#25C156;
    font-size:20px;
    text-align: center;
    line-height: 60px;
    border-radius: 30px;
    cursor: pointer;
    margin-bottom: 34px;
    font-weight: 600;
  }
  .ruzhu-desc{
    font-size: 22px;
    color:#fff;
    text-align: center;
    display: block;
  }
}

.store-wrap{
  width:100%;
  height:600px;
  background: #ffffff;
  padding-top:80px;
  text-align: center;
  .store-title{
    font-size:40px;
    color:#333333;
    font-weight: 600;
  }
  .storelist{
    width:1001px;
    height:361px;
    margin:58px auto 0;
    overflow: hidden;
    position: relative;
    #img-box{
      position: absolute;
      top: 0;
      left: 0;
      #move-img{
        // width: 7293px;
        height: 361px;
      }
    }
    
    .flur-before{
      width: 45px;
      height: 361px;
      background: url(../images/flurbg.png) no-repeat;
      background-size: 45px 361px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .flur-after{
      width: 45px;
      height: 361px;
      background: url(../images/flurbg.png) no-repeat;
      background-size: 45px 361px;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
      background-origin: padding-box;
    }
  }
}
.footer-bar-wrap{
  width:100%;
  height: 526px;
  background: #1C1C1C;
  .footer-content{
    width:1000px;
    margin:0 auto;
    position: relative;
    padding-top:60px;
    box-sizing: border-box;
    margin-bottom: 30px;
    .footer-left{
    //   display: inline-block;
    //   width: 50%;
      width: 100%;
      vertical-align: top;
      .service,.about,.report,.cooperation{
        display: inline-block;
        vertical-align: top;
        color:#ffffff;
        margin-bottom: 40px;
        // width:240px;
        width: 25%;
        .footter-title{
          font-size: 20px;
          color:#ffffff;
          margin-bottom: 20px;
          line-height: 20px;
          display: block;
        }
        .footter-phone{
          color:#838383;
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 14px;
          display: block;
        }
        .li-wrap{
          display: inline-block;
          width: 100%;
          // height: 50px;
          line-height: 14px;
          .li-contain{
            margin-bottom: 14px;
            .a-item{
              list-style: none;
              margin-right: 10px;
              color:#838383;
              font-size: 14px;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
      }
    }
    .footer-right{
    //   display: inline-block;
    //   width: 50%;
      width: 100%;
      vertical-align: top;
      .right-content{
          padding-bottom: 34px;
          box-sizing: border-box;
        .right-title{
          text-align: left;
          color:#fff;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
  .line-box{
    position: relative;
    .line{
      position: absolute;
      left: 0;
      top: -30px;
      width: 100%;
      height: 1px;
      border-top: 1px solid #666;
      -webkit-transform: scaleY(0.5);
      -moz-transform: scaleY(0.5);
      -ms-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
  .beian, .medical, .address, .certificate{
    color:#838383;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .beian, .medical, .certificate {
    margin-bottom: 14px;
  }
  .beian a {
    color:#838383;
    font-size: 14px;
    line-height: 14px;
  }
  .medical a {
    color:#838383;
    font-size: 14px;
    line-height: 14px;
  }
  .certificate a {
    color:#838383;
    font-size: 14px;
    line-height: 14px;
  }
}
.alert-wrap{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 10002;
  background:rgba(0,0,0,0.5);
  &.isMobile{
    .box-wrap{
      width: 80vw;
      height: auto;
      padding: 20px;
      .submit-title{
        font-size: 16px;
      }
      .submit-desc{
        font-size: 12px;
      }
      img{
        width: 50px!important;
        height: 50px!important;
      }
      .submit-btn {
        width: 100%;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
      }
      .close-btn{
        width: 40px;
        height: 35px;
        .close-x {
          height: 35px;
          font-size: 18px;
          line-height: 35px;
          display: inline-block;
          vertical-align: top;
          margin: 0;
        }
      }

    }
  }
  .box-wrap{
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width:460px;
    height:332px;
    background:#ffffff;
    border-radius:16px;
    padding:41px 48px 0px;
    box-sizing: border-box;
    .submit-title{
      width:100%;
      font-size: 26px;
      color:#333333;
      display: block;
      margin:25px auto 10px;
      text-align: center;
    }
    .submit-desc{
      width:100%;
      font-size: 20px;
      color:#999999;
      display: block;
      margin:0 auto 31px;
      text-align: center;
    }
    .submit-btn{
      width:246px;
      height:50px;
      margin:0 auto;
      background:rgba(37,193,86,1);
      border-radius:25px;
      text-align: center;
      line-height: 50px;
      color:#fff;
      cursor: pointer;
    }
    .close-btn{
      position: absolute;
      top:0;
      right:0;
      width:60px;
      height:45px;
      background:rgba(255,255,255,1);
      box-shadow:0px 1px 4px 0px rgba(171,171,171,0.3);
      border-radius:0px 16px 0px 23px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      text-align: center;
      line-height: 45px;
      .close-x{
        width:30px;
        height:30px;
        font-size: 30px;
        color:#cccccc;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        display: inline-block;
        margin-top: 7px;
      }
    }
  }
}

.input-wrap{
  display: inline-block;
  .input-title{
    color:#333333;
    font-size: 18px;
    display: block;
    margin: 0px 0px 15px 10px;
  }
  .input-box{
    width: 416px;
    height: 40px;
    background: #F2F2F2;
    input::-webkit-input-placeholder {
      color: #999999;
    }
    textarea::-webkit-input-placeholde{
      color: #999999;
    } 
     textarea::-moz-placeholder {
      color: #999999;
    }
    textarea:-ms-input-placeholder {
      color: #999999;
    }
    textarea{
      resize:none;
      padding: 5px 10px;
    }
  }

  &.textarea{
    position: relative;
    .notice{
      color: #CCCCCC;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }
}

.guide-wrap{
  width: 100%;
  text-align: center;
  .guide-content{
    width: 1000px;
    margin: 0 auto;
    position: relative;
    padding: 55px 0px 15px;
    .guide-list{
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      .guide-item-block{
        display: inline-block;
        width: 164px;
        height: 240px;
        padding: 18px 10px; 
        box-shadow: 0px 2px 7px 0px rgba(111,111,111,0.2);
        border-radius: 6px;
        box-sizing: border-box;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        .box{
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          .guide-item-title{
            font-size: 20px;
            color: #333;
            font-weight: 600;
            margin-top: 25px;
          }
        }
      }
      .ml{ margin-left: 15px;}
      .guide-item-block:hover{
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        border: 1px solid #2CD471;
        .guide-item-title{
          color:#2CD471;
        }
      }
    }
  }
}

.energize-title-wrap{
  text-align: center;
  padding-top: 70px;
  .energize-title1{
    font-size: 38px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 15px;
  }
  .energize-title2{
    font-weight: 400px;
    font-size: 18px;
  }
}
.advantage-item-wrap{
  display: inline-block;
  vertical-align: top;
  text-align: center;
//   width: 225px;
  width: 33.33%;
  padding: 30px 0px;
  // border: 1px solid red;
  .item-box{
    padding: 10px 20px 0px;
    height: 240px;
    .advantage-item{
      color: #fff;
      .advantage-title{
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
        padding: 0px 5px 5px;
        display: inline;
        position: relative;
        z-index: 1;
        span::after{
          width: 100%;
          height: 20px;
          content: "";
          background: #2cd471;
          opacity: 0.5593;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
        }
      }
      .advantage-details{
        text-align: left;
        margin-top: 33px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.store2-item-wrap{
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  width: 192px;
  .store2-arrow{
    width: 41px;
    height: 52px;
    background: linear-gradient(#eaeaea, #ffffff);
    margin: 0 auto;
    position: relative;
    .left-top{
      width: 0;
      height: 0;
      border-top: 20px solid #fff;
      border-right: 20px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
    }
    .left-bottom{
      width: 8px;
      height: 32px;
      position: absolute;
      background: #ffffff;
      bottom: 0;
      left: 0;
    }
    .right-top{
      width: 0;
      height: 0;
      border-top: 20px solid #fff;
      border-left: 20px solid transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
    .right-bottom{
      width: 8px;
      height: 32px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #ffffff;
    }
  }
  .store2-title{
    margin: 10px 0px 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px
  }
  .store2-details{
    font-size: 12px;
    color: #333;
    line-height: 17px;
    font-weight: 500;
  }
}
.ml5{
  margin-left: 262px;
}

.goods-item-wrap{
  display: inline-block;
  vertical-align: top;
  width: 222px;
  padding: 0px 35px 20px 30px;
  .goods-item-left{
    float: left;
    height: 100%;
    margin-right: 10px;
  }
  .goods-item-right{
    text-align: left;
    margin-left: 45px;
    .goods-right-title{
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 8px;
      position: relative;
    }
    .goods-right-title::before{
      content: '';
      position: absolute;
      width: 44px;
      height: 3px;
      left: 0;
      top: 33px;
      background: #12c350;
      border-radius: 1px;
    }
    .goods-right-content{
      height: 105px;
      margin-top: 20px;
      font-size: 16px;
    }
  }
}
.goods-item-wrap::after{
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
}
.top-content-btn{
  display: inline-block;
  .btn-item-wrap{
    width: 120px;
    height: 30px;
    background: #25c156;
    border-radius: 4px;
    .btn-item{
      width: 100%;
      height: 100%;
      color: #fff;
      text-align: center;
      vertical-align: middle;
      .btn-top-img{
        margin-right: 5px;
        vertical-align: middle;
      }
      .btn-title{
        font-size: 12px;
        line-height: 30px;
        font-weight: 400;
        vertical-align: middle;
      }
    }
  }
  .btn-img-wrap{
    width: 120px;
    height: 100px;
    background: rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    visibility: visible;
    .btn-img-box{
      position: relative;
      text-align: center;
      .btn-img{
        height: 100px;
        padding-top: 8px; 
      }
    }
  }
}
.ml{
  margin-left: 20px;
}

.rule-contents-item {
  .content-title1 {
    width: 100%;
    height: 48px;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    .content-title {
      width: 148px;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      letter-spacing: 0px;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .arrowIcon {
      width: 12px;
      height: 8px;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
  }
  .content-title2 {
    display: block;
    width: 100%;
    height: 48px;
    background: #ffffff;
    padding-left: 60px;
    padding-right: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.rule-contain-list-wrap {
  padding-left: 60px;
  padding-right: 40px;
  cursor: default;
  .rule-contain-list-title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    text-align: left;
    padding-bottom: 40px;
    border-bottom: 1px solid #e4e4e4;
  }
  .rule-contain-list-scroll {
    text-align: left;
    // padding: 30px 0 20px;
    padding: 30px 0;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
    position: relative;
    .topline, .bottomLine {
      width: 819px;
      height: 50px;
      position: absolute;
      left: 0;
      z-index: 10;
    }
    .topline {
      background: url('../images/icon/boxBg.png') no-repeat;
      background-size: 819px 50px;
      top: 0;
    }
    .bottomLine {
      background: url('../images/icon/boxBg.png') no-repeat;
      background-size: 819px 50px;
      bottom: 0;
      transform: rotate(180deg);
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul li {
      height: 37px;
      line-height: 37px;
      i {
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #cccccc;
        margin-right: 10px;
        vertical-align: middle;
      }
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #333333;
        vertical-align: middle;
      }
    }
    li:hover span{
      color: #46BF6C;
    }
    li:hover i {
      background-color: #46BF6C;
    }
  }
  .rule-contain-list-box {
    text-align: left;
    .rule-contain-list {
      width: 100%;
      overflow-y: scroll;
      cursor: pointer;
      .rule-contain-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        padding-bottom: 30px;
      }
    }
  }
}
.paging-box {
  margin-top: 84px;
  text-align: center;
}
.rule-contain-wrap {
  min-height: 690px;
  padding-left: 60px;
  .rule-contain-path {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .rule-contain-title {
    padding-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333;
    text-align: left;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }
  .rule-contain {
    padding-top: 39px;
    padding-right: 60px;
    text-align: left;
  }
}
.pagination-wrap {
  height: 34px;
  cursor: default;
  .pageBox {
    width: 34px;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    display: inline-block;
  }
  .page-number {
    display: inline-block;
    .current-page {
      width: 34px;
      height: 34px;
      background-color: #46BF6C;
      border-radius: 3px;
      font-size: 14px;
      color: #ffffff;
      display: inline-block;
      margin-left: 4px;
      vertical-align: top;
      line-height: 34px;
      cursor: text;
    }
    .next {
      margin-left: 4px;
      transform: rotate(180deg);
    }
    .prev, .next {
      background-size: 6px 10px;
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .total-page {
    display: inline-block;
    margin-left: 20px;
    height: 34px;
    line-height: 34px;
    vertical-align: top;
    font-size: 12px;
    color: #666666;
    // border: 1px solid red;
  }
  .goto-page-box {
    display: inline-block;
    margin-left: 20px;
    height: 34px;
    line-height: 34px;
    vertical-align: top;
    // border: 1px solid red;
    span {
      font-size: 12px;
      color: #666666;
    }
    .goto-page {
      margin: 0 4px;
      vertical-align: top;
      outline: none;
      background: none;
      text-align: center;
      font-size: 14px;
      color: #333333;
    }
  }
}