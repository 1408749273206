.module-wrap {
    padding: 60px 0;
    text-align: center;
    .title-wrap {
        text-align: center;
        margin-bottom: 56px;
        .title {
            font-size: 32px;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            color: #1579F4;
        }
        .subTitle {
            margin-top: 10px;
            font-size: 18px;
            color: #999;
            font-weight: 500;
        }
    }
    .bottom-wrap {
        text-align: center;
        div {
            width: 230px;
            height: 54px;
            background: linear-gradient(270deg, #1D81FC 0%, #5FB1FF 100%);
            text-align: center;
            font-size: 22px;
            color: #FFFFFF;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 54px;
            margin: 0 auto;
            cursor: pointer;
        }
        .phone {
            margin-top: 10px;
            font-size: 18px;
            color: #999;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 26px;
        }
    }
}
.card-wrap {
    display: inline-block;
    position: relative;
    margin-left: 20px;
    margin-bottom: 40px;
    .card-box {
        display: inline-block;
        width: 215px;
        height: 150px;
        background: #FFFFFF;
        box-shadow: 2px 2px 9px 6px #ECF5FF;
        border-radius: 16px;
        vertical-align: middle;
        position: relative;
        overflow: hidden;
        .title {
            font-size: 20px;
            font-weight: bold;
            color: #333;
            margin-top: 42px;
        }
        .desc-list {
            margin-top: 14px;
            p {
                font-size: 14px;
                color: #999;
                font-weight: bold;
            }
        }
        .line {
            width: 100%;
            height: 5px;
            background: #1D81FC;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .desc {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 500;
            color: #999;
            text-align: left;
        }
    }
    .icon {
        width: 35px;
        height: 31px;
        line-height: 28px;
        background: url('../../images/haiboLading/iconBg.png') no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 19px;
        top: -7px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        box-sizing: border-box;
    }
}
