html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* 苹方 微软雅黑 黑体 Helvetica Arial */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: PingFangSC, 苹方, "Microsoft YaHei", 微软雅黑, SimHei, 黑体, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{text-decoration:none}

p, div, input{margin: 0; padding: 0;}
