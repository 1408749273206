.top-image{
  width: 100%;
  min-width: 1000px;
  position: relative;
  .zhanwei{
    width: 100%;
    height: 0;
    padding-bottom: 33.3%;
    background: #f6f6f6;
    display: block;
  }
  .buzhanwei{
    width: 100%;
    height: 100%;
    display: block;
  }
  .top-content{
    position: absolute;
    top: 51%;
    left: 19%;
    .top-content-box{
      width: 540px;
    }
  }
}

.top-image-report{
  width: 100%;
  min-width: 1280px;
  height:640px;
  background: url(../images/reportImg.jpg) no-repeat;
  background-size: 100% auto;
  padding-top: 22vh;
  margin-bottom:150px;
  .middle-content{
    width:1000px;
    height:700px;
    margin:0 auto;
    .report-title{
      font-size: 60px;
      color:#fff;
      width:400px;
      height: 60px;
      margin:0 0 36px 51px;
      line-height: 60px;
      font-weight: 600;
    }
    .report-desc{
      font-size: 35px;
      color:#fff;
      width:900px;
      height: 35px;
      margin-left: 50px;
      line-height: 46px;
      font-weight: 400;
      white-space: nowrap;
    }
    .white-bg{
      margin-top:100px;
      width:100%;
      background: #ffffff;
      border-radius: 6px;
      box-shadow:0px 2px 7px 0px rgba(111,111,111,0.2);
      padding:50px;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      .line-box{
        position: relative;
        padding-bottom: 50px;
        .line{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 1px;
          border-top: 1px solid #f6f6f6;
        }
      }
      .repTel{
        font-size: 20px;
      }
    }
  }
  
}

.top-image-ruzhu{
  width: 100%;
  min-width: 1000px;
  .zhanwei{
    width: 100%;
    height: 0;
    padding-bottom: 33.3%;
    background: #f6f6f6;
    display: block;
  }
  .buzhanwei{
    width: 100%;
    height: 100%;
    display: block;
  }
}
.ruzhu-four{
  background: #F1F6F3;
  width: 100%;
  height: 670px;
  margin:0 auto; 
  .ruzhu-content{
    width:1000px;
    height:700px;
    margin:0 auto; 
    padding:70px 0;
    .ruzhu-title{
      width: 350px;
      height:66px;
      margin: 0 auto 50px;
      font-size: 40px;
      color:#333333;
      display: block;
      font-weight: 600;
    }
    .youshi-list{
      .ruzhu-youshi{
        display: inline-block;
        vertical-align: top;
        width:239px;
        height: 414px;
        background: #fff;
        margin-right: 10px;
        padding: 14px 14px 20px;
        box-sizing: border-box;
        border-radius: 6px;
        .youshi-img{
          width: 107px;
          height: 107px;
          display: block;
          margin:0 auto;
          box-sizing: border-box;
        }
        .youshi-title{
          color:#2CD471;
          font-size: 25px;
          text-align: center;
          font-weight: 400;
          margin: 5px 0 24px;
        }
        .youshi-name{
          height: 60px;
          color:#333333;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .youshi-content{
          color:#333333;
          font-size: 16px;
          text-align: left;
        }
      }
    }
  }
}
.ruzhu-step{
  background: #FFFFFF;
  width: 100%;
  margin:0 auto; 
  .step-content{
    width:1000px;
    margin:0 auto; 
    padding:70px 0;
    .step-title{
      width: 350px;
      height:66px;
      margin: 0 auto 57px;
      font-size: 40px;
      color:#333333;
      display: block;
      font-weight: 600;
    }
    .step-1{
      position: relative;
      .left-line{
        width: 1px;
        height: 93%;
        border-left: 1px dashed #ccc;
        position: absolute;
        top: 60px;
        left: 4px;
      }
      .step-order{
        margin-bottom: 65px;
        .step-num{
          display: inline-block;
          font-size: 86px;
          color:#25C156;
          height: 86px;
          line-height: 86px;
          margin-right: 13px;
          vertical-align: middle;
        }
        .step-pot{
          width:10px;
          height:10px;
          display: inline-block;
          background:#25C156;
          margin-right: 10px;
          vertical-align: middle;
        }
        .step-text{
          display: inline-block;
          font-size: 35px;
          color:#333333;
          font-weight: 600;
          vertical-align: middle;
        }
      }
      .step-list{
        width: 100%;
        margin-left: 20px;
        box-sizing: border-box;
        .step-item{
          width:20%;
          display: inline-block;
          height:124px;
          margin-bottom: 60px;
          text-align: center;
          .step-item-name{
            font-size: 18px;
            color:#333333;
            text-align: center;
            display: block;
            margin-top: 17px;
          }
        }
      }
    }
    .step-2{
      margin-bottom:110px;
      width: 1036px;
      position: relative;
      .left-line{
        width: 1px;
        height: 124%;
        border-left: 1px dashed #ccc;
        position: absolute;
        top: 60px;
        left: 4px;
      }
      .step-order{ 
        margin-bottom:70px;
        .step-num{
          display: inline-block;
          vertical-align: middle;
          font-size: 86px;
          color:#25C156;
          height:86px;
          line-height: 86px;
          margin-right: 13px;
        }
        .step-pot{
          width:10px;
          height:10px;
          display: inline-block;
          vertical-align: middle;
          background:#25C156;
          margin-right: 10px;
        }
        .step-text{
          display: inline-block;
          vertical-align: middle;
          .text-up{
            font-size: 35px;
            color:#333333;
            font-weight: 600;
          }
          .text-down{
            font-size: 18px;
            color:#333333;
            white-space: nowrap;
          }
        }
      }
      .store-step{
        padding-left: 20px;
        .store-step-1{
          display: inline-block;
          text-align: center;
          vertical-align: top;
          .store-step-img{
              width: 220px;
              height: 79px;
              display: block;
              margin: 0 8px 35px;
          }
        }
        .store-step-arrow{
          width: 21px;
          height: 18px;
          margin-top: 30px;
          display: inline-block;
          vertical-align: top;
        }
      }
      
    }
    .step-2::after{
    }
    .step-3{
      width: 1036px;
      .step-order{
        width: 1100px;
        margin-bottom: 50px;
        .step-num{
          display: inline-block;
          vertical-align: middle;
          font-size: 86px;
          color:#25C156;
          height:86px;
          line-height: 86px;
          margin-right: 13px;
        }
        .step-pot{
          width:10px;
          height:10px;
          display: inline-block;
          vertical-align: middle;
          background:#25C156;
          margin-right: 10px;
          
        }
        .step-text{
          display: inline-block;
          vertical-align: middle;
          .text-up{
            font-size: 35px;
            color:#333333;
            font-weight: 600;
          }
          .text-down{
            font-size: 18px;
            color:#333333;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.submit-form{
  padding: 44px 55px 70px;
  background: #FBFBFB;
  border-radius: 6px;
  .from-1{
    margin-bottom: 13px;
    .fieldType {
      display: inline-block;
      vertical-align: top;
      .fieldTitle {
        color:#333333;
        font-size: 18px;
        display: block;
        margin: 0px 0px 15px 10px;
      }
      .selectBox {
        width: 418px;
        height: 42px;
        background-color: #F2F2F2;
        padding: 0px 30px 0 10px;
        box-sizing: border-box;
        position: relative;
        .selectName {
          line-height: 42px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }
        .selectIcon {
          width: 13px;
          height: 8px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        .selectTip {
          color: #FB4E44;
          font-size: 16px;
          font-weight: 400;
        }
        .selectOptions {
          width: 418px;
          max-height: 246px;
          overflow-y: scroll;
          background-color: #ffffff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          position: absolute;
          left: 0;
          top: 42px;
          z-index: 9;
          .optionItem {
            position: relative;
            .optionName {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 38px;
              padding: 0px 20px 0px 10px;
              box-sizing: border-box;
            }
            img {
              width: 17px;
              height: 11px;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
            }
          }
          .optionItem:hover {
            background-color: #F9F9F9;
          }
          .optionItem:hover .optionName {
            color: #46BF6C;
          }
        }
        // .selectOptions::-webkit-scrollbar { // 滚动条整体样式
        //   background: transparent;
        //   width: 2px;
        // }
        // .selectOptions::-webkit-scrollbar-track{ // 滚动条里面的轨道
        //   // display: none;
        //   background: black;
        //   border-radius: 2px;
        //   height: 10px;
        // }
        // .selectOptions::-webkit-scrollbar-thumb{ // 滚动条里面的方块
        //   background: #cccccc;
        //   border-radius: 10px;
        // }
      }
    }
  }
  .submit-btn{
    width:320px;
    height:60px;
    background: #25C156;
    border-radius:35px;
    text-align: center;
    line-height: 60px;
    font-weight: 600;
    color:#ffffff;
    font-size: 20px;
    margin:50px auto  0;
    cursor: pointer;
  }
}
.qrCode {
  width: 130px;
  height: 158px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #ffffff;
  position: fixed;
  right: 0;
  bottom: 15%;
  margin-right: 105px;
  text-align: center;
  z-index: 100;
  div {
    padding: 17px 0px 0px;
    text-align: center;
  }
  img {
    display: block;
    width: 100px;
    height: 100px;
    padding: 5px 0 15px;
    margin: 0 auto;
  }
  .joy {
    width: 112px;
    height: 70px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -57px;
    padding: 0;
  }
  .tipsContainer {
    margin-top: 14px;
    padding-top: 0px;
    width: 132px;
    height: 44px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    border-radius: 44px;
  }
  .tips {
    padding-top: 0px;
    width: 132px;
    height: 44px;
  }
}

.top-image-energize{
  width: 100%;
  min-width: 1000px;
  .zhanwei{
    width: 100%;
    height: 0;
    padding-bottom: 33.3%;
    background: #f6f6f6;
    display: block;
  }
  .buzhanwei{
    width: 100%;
    height: 100%;
    display: block;
  }
}
.energize-flow{
  width: 100%;
  min-width: 1000px;
  .flow-content{
    margin-top: -25px;
    .flow-top-content{
      padding-top: 25px;
      .flow-top-box{
        color: #12c350;
        position: relative;
        text-align: center;
        .flow-left-box{
          width: 600px;
          height: 459px;
          padding-top: 100px;
          padding-right: 370px;
          margin: 0 auto;
          .flow-left-phone{
            width: 600px;
            height: 459px;
          }
        }
        .flow-right-box{
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          transform: translateY(-50%);
          .flow-right-title{
            font-size: 16px;
            text-align: left;
            .title1{
              font-size: 30px;
              font-weight: 600;
              line-height: 30px;
              margin-bottom: 10px;
              span{
                font-weight: bold;
                font-size: 38px;
                line-height: 30px;
              }
            }
            .title2{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
          .flow-right-img{
            margin-top: 45px;
            padding-right: 20px;
            .path-item{
              text-align: center;
              display: inline-block;
              p{
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
              }
            }
            .ml{
              margin-left: 124px;
            }
          }
        }
      }
    }
    .flow-bottom-content{
      width: 100%;
      min-width: 900px;
      height: 384px;
      background: #25c156;
      text-align: center;
      .flow-bottom-box{
        width: 900px;
        height: 100%;
        color: #fff;
        font-size: 16px;
        margin: 0 auto;
        text-align: left;
        position: relative;
        .first-box{
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          transform: translateY(-50%);
          .first{
            font-size: 22px;
            width: 400px;
            margin-bottom: 20px;
            font-weight: 600;
          }
        }
        .bottom-img{
          position: absolute;
          right: 0;
          top: -30px;
        }
      }
    }
  }
}
.energize-performance{
  width: 100%;
  background: #fff;
  .performace-content-wrap{
    padding: 60px 0px 80px;
    text-align: center;
    .performace-content{
      margin: 0 auto;
      width: 914px;
      display: block;
      .left-line{
        width: 6px;
        // height: 1511px;
        height: 1944px;
        background: linear-gradient(rgba(18, 195, 80, 1) 70%, rgba(41, 227, 136, 0));
        display: inline-block;
        vertical-align: top;
        margin-left: 12px;
      }
      .line-right{
        display: inline-block;
        vertical-align: top;
        margin-left: 16px;
        .goods-taken-wrap{
          position: relative;
          padding-top: 80px;
          padding-bottom: 55px;
          .goods-taken1{
            padding-top: 75px;
            padding-bottom: 45px;
            position: relative;
            .goods-taken1-content{
              .goods-taken1-list{
                .taken1-item-wrap{
                  display: inline-block;
                  vertical-align: top;
                  width: 430px;
                  background: #fff;
                  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
                  border-radius: 5px;
                  position: relative;
                  .taken1-item{
                    padding: 20px 25px;
                    text-align: center;
                    .taken1-img{
                      width: 50px;
                      height: 42px;
                      position: absolute;
                      top: -5px;
                      left: 20px;
                    }
                  }
                  .line{
                    width: 430px;
                    height: 7px;
                    background: #12c350;
                    border-radius: 0px 0px 6px 6px;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                  }
                }
              }
            }
          }
          .goods-taken2{
            padding-top: 70px;
            padding-bottom: 150px;
            position: relative;
            .taken2-content{
              .taken2-content-list{
                .item-box-wrap{
                  margin: 145px 15px;
                  .item-box{
                    height: 140px;
                    background: #fff;
                    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
                    border-radius: 6px;
                    text-align: center;
                    padding-top: 20px;
                    .item-title{
                      font-size: 24px;
                      color: #333;
                      font-weight: 500;
                      line-height: 24px;
                      height: 30px;
                      padding: 0px 5px 5px;
                      display: inline;
                      position: relative;
                      z-index: 1;
                      span::after{
                        width: 100%;
                        height: 20px;
                        content: "";
                        background: #17c353;
                        opacity: 0.5593;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: -1;
                      }
                    }
                    .item-desc{
                      font-size: 14px;
                      color: #333;
                      line-height: 22px;
                      text-align: left;
                      padding: 25px 15px 0px;
                    }
                  }
                }
              }
              .taken2-content2 {
                  margin-top: 138px;
                  .selectBg {
                      width: 880px;
                      height: 196px;
                      background: url('../images/selectBetter.png') center center no-repeat;
                      background-size: 880px 196px;
                      .select-box-wrap {
                        padding: 145px 30px 0 30px;
                        box-sizing: border-box;
                        .select-box {
                            height: 160px;
                            background: #ffffff;
                            padding-top: 20px;
                            box-sizing: border-box;
                            box-shadow: 0px 2px 7px 0px rgba(111, 111, 111, 0.2);
                            border-radius: 6px;
                            font-size: 24px;
                            .select-title {
                                font-size: 24px;
                                color: #333;
                                font-weight: 500;
                                line-height: 24px;
                                height: 30px;
                                padding: 0px 5px 5px;
                                display: inline;
                                position: relative;
                                z-index: 1;
                                span::after{
                                    width: 100%;
                                    height: 20px;
                                    content: "";
                                    background: #17c353;
                                    opacity: 0.5593;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    z-index: -1;
                                }
                            }
                            .select-desc {
                                font-size: 16px;
                                padding: 24px 15px 0 15px;
                                text-align: left;
                            }
                        }
                      }
                  }
              }
            }
          }
          .goods-taken3{
            position: relative;
            padding-top: 75px;
            .taken3-content{
              .tools-item{
                padding: 20px 20px 0;
                .tools-name{
                  font-size: 28px;
                  font-weight: 500;
                  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                  line-height: 40px;
                }
                .tools-desc{
                  text-align: left;
                  margin-top: 20px;
                  font-size: 20px;
                  line-height: 27px;
                }
              }
            }
          }
        }
        .logistics{
          position: relative;
          .logistics-list{
            padding-top: 79px;
            .logistics-item{
              display: inline-block;
              width: 430px;
              background: #fff;
              box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
              border-radius: 6px;
              vertical-align: top;
              .logistics-item-content{
                padding: 50px 25px 30px 30px;
                text-align: center;
                .p1{
                  color: #333333;
                  font-size: 28px;
                  font-weight: 500;
                  line-height: 40px;
                  display: inline;
                  position: relative;
                  padding: 0 5px;
                  z-index: 1;
                  span::after{
                    width: 100%;
                    height: 20px;
                    content: "";
                    background: #2cd471;
                    opacity: 0.5593;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                  }
                }
                .p2{
                  color: #383830;
                  font-size: 16px;
                  text-align: left;
                  line-height: 22px;
                  margin-top: 10px;
                  height: 66px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.goods-taken-title{
  // display: flex;
  // align-items: center;
  position: absolute;
  top: -6px;
  left: -35px;
  img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  p{
    display: inline-block;
    vertical-align: middle;
    color: #333;
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
  }
}
.goods-taken-ertitle{
  position: absolute;
  top: 0;
  left: -18px;
  .line{
    width: 75px;
    height: 2px;
    background: #12c350;
    display: inline-block;
    vertical-align: middle;
  }
  .circle{
    display: inline-block;
    vertical-align: middle;
    width: 11px;
    height: 11px;
    background: transparent;
    border-radius: 50%;
    border: 4px solid #12c350;
  }
  .text{
    display: inline-block;
    vertical-align: middle;
    color: #333;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    margin-left: 15px;
  }
}

.energize-user{
  width: 100%;
  background: #12c350;
  position: relative;
  .user-triangle{
    width: 288px;
    height: 90px;
    background: url(../images/user_triangle.png) no-repeat;
    background-size: 288px 90px;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .user-wrap{
    text-align: center;
    padding: 50px 0px 100px;
    .user-list{
      width: 900px;
      margin: 0 auto;
      .ml{
        margin-left: 12px;
      }
      .user-item-wrap{
        display: inline-block;
        width: 292px;
        background: #fff;
        box-shadow: 0px 2px 7px 0px rgba(111,111,111,0.2);
        border-radius: 6px;
        vertical-align: top;
        .user-item-bottom{
          padding: 25px 22px 22px;
          .user-item-title{
            color: #333;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            height: 24px;
            padding: 0px 15px 6px;
            display: inline;
            position: relative;
            z-index: 1;
            span::after{
              width: 100%;
              height: 20px;
              content: "";
              background: #2cd471;
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: -1;
            }
          }
          .user-item-detils{
            height: 88px;
            text-align: left;
            padding-top: 25px;
            font-size: 14px;
            color: #333;
            line-height: 22px;
          }
        }
      }
    }
  }
}
.energize-goods{
  width: 100%;
  background: #f7f7f7;
  .goods-wrap{
    text-align: center;
    padding: 70px 0px 120px 0px;
    .goods-list{
      width: 900px;
      margin: 0 auto;
    }
  }
}
.energize-store{
  width: 100%;
  background: #fff;
  padding-bottom: 70px;
  .energize-store-content{
    text-align: center;
    margin-top: 35px;
    .store-content-wrap{
      width: 900px;
      margin: 0 auto;
      .store-content1{
        height: 300px;
        background: #12c350;
      }
      .store-content2{
      }
    }
  }
}

// 规则中心
.rulecenter-page {
  background-color: #ffffff;
  .top-rulecenter{
    width: 100%;
    min-width: 1000px;
  }
  .rulecenter-wrap {
    // 上部导航68px,间距40px
    padding: 108px 0 60px;
    width: 100%;
    text-align: center;
    .rulecenter-box {
      margin: 0 auto;
      width: 1200px;
      .rule-contents {
        display: inline-block;
        width: 240px;
        padding: 26px 0;
        background-color: #fff;
        box-shadow: 0px 2px 15px 0px rgba(111,111,111,0.1);
        border-radius: 6px;
        margin-right: 40px;
        box-sizing: border-box;
        vertical-align: top;
      }
      .rule-contain-box {
        display: inline-block;
        width: 919px;
        padding: 40px 0;
        background-color: #fff;
        box-shadow: 0px 2px 15px 0px rgba(111,111,111,0.1);
        border-radius: 6px;
        box-sizing: border-box;
        vertical-align: top;
      }
    }
  }
}

// 海博系统
.top-image-haibo{
    width: 100%;
    min-width: 1000px;
    .zhanwei{
        width: 100%;
        height: 0;
        padding-bottom: 33.3%;
        background: #f6f6f6;
        display: block;
    }
    .buzhanwei{
        width: 100%;
        height: 100%;
        display: block;
    }
}
.haibo-title {
    font-size: 40px;
    color: #333;
    line-height: 56px;
    font-weight: 600;
    margin: 0 auto;
}
.haibo-content {
    font-family: PingFangSC-Regular;
    .operationHelp {
        padding: 54px 0 53px 0;
        box-sizing: border-box;
        text-align: center;
        .operationHelp_desc {
            width: 600px;
            margin: 20px auto 0 auto;
            font-size: 18px;
            color: #333;
            line-height: 24px;
        }
        .operationHelpProcess {
            margin-top: 62px;
            text-align: center;
            .processContent {
                width: 968px;
                margin: 0 auto;
                position: relative;
                .processLine {
                    width: 6px;
                    height: 1395px;
                    background: linear-gradient(rgba(18, 195, 80, 1) 70%, rgba(41, 227, 136, 0));
                    position: absolute;
                    top: 17px;
                    left: 14px;
                    z-index: -1;
                }
                .disadvantage {
                    padding-bottom: 62px;
                    box-sizing: border-box;
                    text-align: left;
                    .disadvantage_title {
                        vertical-align: middle;
                        img {
                            width: 34px;
                            height: 34px;
                            display: inline-block;
                            vertical-align: bottom;
                        }
                        span {
                            font-size: 26px;
                            color: #333;
                            font-weight: 600;
                            margin-left: 28px;
                            .spec {
                                color: #FF880C;
                                margin-left: 0;
                            }
                        }
                    }
                    .disadvantage_list {
                        text-align: right;
                        margin-top: 56px;
                        p {
                            display: inline-block;
                            width: 140px;
                            height: 50px;
                            font-size: 24px;
                            line-height: 50px;
                            color: #FFFFFF;
                            font-weight: 600;
                            background: #FF880C;
                            border-radius: 8px;
                            margin-left: 86px;
                        }
                    }
                }
                .methods {
                    text-align: left;
                    .methods_title {
                        margin: 0 auto;
                        img {
                            width: 34px;
                            height: 34px;
                            vertical-align: bottom;
                        }
                        span {
                            margin-left: 13px;
                            font-size: 26px;
                            color: #333;
                            font-weight: 600;
                        }
                    }
                    .method1 {
                        width: 831px;
                        height: 492px;
                        margin: 35px 0 0 137px;
                    }
                    .method2 {
                        width: 831px;
                        height: 368px;
                        margin: 35px 0 0 137px;
                    }
                }
            }
        }
    }
    .useEffect {
        padding-bottom: 80px;
        box-sizing: border-box;
        text-align: center;
        .useEffectDesc {
            margin-top: 20px;
            font-size: 18px;
            line-height: 25px;
            color: #333;
        }
        .useEffect_list {
            text-align: center;
            .efficiency {
                width: 870px;
                margin: 0 auto;
                padding-top: 8px;
                box-sizing: border-box;
            }
            .cost {
                width: 880px;
                padding-top: 53px;
                box-sizing: border-box;
                margin: 0 auto;
            }
            .sales {
                width: 912px;
                margin: 0 auto;
                padding-top: 8px;
                box-sizing: border-box;
            }
        }
    }
    .operationOrg {
        text-align: center;
        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 25px;
            color: #333;
        }
    }
    .apply {
        width: 100%;
        background: #12c350;
        position: relative;
        color: #FFFFFF;
        text-align: center;
        margin-top: 125px;
        padding-bottom: 90px;
        box-sizing: border-box;
        .apply-triangle{
            width: 288px;
            height: 90px;
            background: url(../images/user_triangle.png) no-repeat;
            background-size: 288px 90px;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .apply-title {
            font-size: 40px;
            line-height: 56px;
            font-weight: 600;
            padding-top: 176px;
            box-sizing: border-box;
            margin: 0 auto;
        }
        .apply-desc {
            font-size: 18px;
            line-height: 25px;
            margin: 20px auto 0 auto;
        }
        .system-wrap {
            margin-top: 55px;
            .system-title {
                font-size: 30px;
                line-height: 42px;
                font-weight: 600;
            }
            .system-box {
                width: 1000px;
                margin: 32px auto 0 auto;
                .system-bottom {
                    width: 1000px;
                    height: 59px;
                    background: #ABFFC9;
                    border-radius: 0px 0px 6px 6px;
                    font-size: 24px;
                    line-height: 59px;
                    color: #008B31;
               } 
            }
            .system-service {
                width: 1000px;
                margin: 87px auto 0 auto;
            }
        }
    }
    .newReport {
        padding: 80px 0 135px 0;
        box-sizing: border-box;
        text-align: center;
        p {
            margin-top: 50px;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            a, a:link, a:active, a:visited {
                color: #12C350;
                text-decoration: underline;
            }
            a:hover {
                color: #12C350;
                text-decoration: underline;
                font-size: 28px;
            }
        }
    }
}

// 品牌赋能
.brand-page {
    background-color: #ffffff;
    .top-brand {
        width: 100%;
        min-width: 1000px;
    }
    .brand-content {
        padding: 68px 0px 200px 0px;
        .infrastructure {
            .infrastructureList {
                width: 1098px;
                margin: 45px auto 0 auto;
            }
        }
        .flow {
            .flow-list {
                width: 1038px;
                margin: 35px auto 0 auto;
            }
        }
        .sale {
            .saleList {
                width: 1038px;
                margin: 40px auto 0 auto;
            }
        }
        .operate {
            text-align: center;
            .operateList {
                width: 900px;
                text-align: center;
                margin: -13px auto 0 auto;
                .operateItem {
                    display: inline-block;
                    margin-left: 108px;
                    margin-top: 60px;
                    .img {
                        width: 144px;
                        height: 155px;
                    }
                    .text {
                        font-size: 22px;
                        color: #333;
                        font-weight: 600;
                        margin-top: 14px;
                        text-align: center;
                    }
                }
            }
        }
        .brandEmpowerment {
            width: 100%;
            background: #12C350;
            text-align: center;
            margin-top: 80px;
            padding-bottom: 72px;
            box-sizing: border-box;
            .brandEmpowerment-triangle {
                width: 288px;
                height: 90px;
                background: url(../images/user_triangle.png) no-repeat;
                background-size: 288px 90px;
                margin: 0 auto;
            }
            .partImg {
                width: 580px;
                display: inline-block;
                position: absolute;
                top: 0;
            }
            .brandEmpowermentPart1, .brandEmpowermentPart2, .brandEmpowermentPart3, .brandEmpowermentPart4 {
                width: 1040px;
                height: 492px;
                text-align: left;
                position: relative;
            }
            .brandEmpowermentPart1 {
                margin: 80px auto 0 auto;
            }
            .brandEmpowermentPart2 {
                margin: 160px auto 0 auto;
            }
            .brandEmpowermentPart3 {
                margin: 114px auto 0 auto;
            }
            .brandEmpowermentPart4 {
                margin: 130px auto 0 auto;
            }
        }
    }
}

// 海博仓店管理系统
.haiboLading-page {
    background-color: #FFFFFF;  
    .top-image-haiboLading {
        width: 100%;
        min-width: 1250px;

        position: relative;
        .zhanwei{
            width: 100%;
            height: 0;
            padding-bottom: 33.3%;
            background: #f6f6f6;
            display: block;
        }
        .buzhanwei {
            width: 100%;
            height: 100%;
            display: block;
        }
        .top-content {
            position: absolute;
            width: 577px;
            left: (166 / 1366) * 100%;
            top: (200 / 570) * 100%;
            .top-content-title {
                font-size: 50px;
                color: #333;
                font-weight: bold;
            }
            .top-content-desc {
                font-size: 26px;
                font-weight: 500;
                color: #333;
                margin-top: 25px;
            }
        }
    }
    .top-image-haiboLading::before {
        display: block;
        width: 100%;
        height: 64px;
        content: "";
        background: linear-gradient(270deg, #1D81FC 0%, #5FB1FF 100%);
    }
    .haiboLanding-content {
        .moduleBg0 {
            width: 1115px;
            margin: 0 auto 28px;
            display: block;
        }
        .moduleBg1 {
            width: 815px;
            margin: 0 auto 30px auto;
            display: block;
        }

        .module2List {
            width: 934px;
            padding: 1px 0 30px 0;
            margin:  0 auto;
            span {
                display: inline-block;
                width: 220px;
                height: 43px;
                text-align: center;
                font-size: 22px;
                color: #1579F4;
                font-weight: 500;
                line-height: 43px;
                background: #DCEAFF;
                margin-left: 64px;
                margin-top: 14px;
            }
        }
        .detailsWrap {
            margin-top: 44px;
        }

        .jiantou {
            width: 57px;
            height: 20px;
            margin: 0 10px;
        }
        .module3ProcessItem {
            display: inline-block;
            height: 54px;
            padding: 0 24px;
            box-sizing: border-box;
            background: #FFFFFF;
            border-radius: 15px;
            border: 3px solid #1D81FC;
            font-size: 20px;
            color: #333;
            font-weight: 500;
            line-height: 54px;
        }

        .module4List {
            margin: 0 auto 40px auto;
            .module4Item {
                display: inline-block;
                width: 200px;
                height: 225px;
                background: #FFFFFF;
                box-shadow: 2px 2px 9px 6px rgba(236,245,255,1);
                border-radius: 16px;
                border: 4px solid #1579F4;
                margin-left: 53px;
                padding: 24px 30px 20px 30px;
                box-sizing: border-box;
                text-align: center;
                vertical-align: middle;
                img {
                    width: 42px;
                    height: 42px;
                }
                .title {
                    font-size: 20px;
                    margin-top: 10px;
                    color: #333;
                    font-weight: bold;
                }
                .desc {
                    margin-top: 15px;
                    font-size: 14px;
                    color: #999;
                    font-weight: 500;
                    text-align: left;
                }
            }
        }
        .haiboSysQes {
            width: 1260px;
            margin: 0 auto 40px auto;
            .qesItem {
                display: inline-block;
                width: 280px;
                height: 125px;
                background: #FFFFFF;
                box-shadow: 2px 2px 12px 8px rgba(205,205,205,0.5);
                border-radius: 16px;
                padding: 28px 26px;
                box-sizing: border-box;
                color: #1D81FC;
                font-size: 22px;
                font-weight: bold;
                margin-left: 45px;
                text-align: left;
                span {
                    color: #FF6600;
                }
            }
        }
        
    }
    
    .module7line {
        display: inline-block;
        width: 1px;
        height: 68px;
        background-color: #ddd;
        margin: 0 45px;
    }
    .firstModule {
        display: inline-block;
        text-align: left;
        margin-bottom: 50px;
        div {
            font-size: 24px;
            color: #333;
            font-weight: bold;
        }
        p {
            font-size: 14px;
            color: #333;
            margin-top: 10px;
        }
    }
    .secModule {
        margin-bottom: 40px;
        display: inline-block;
        margin-left: 38px;
        .left {
            display: inline-block;
            width: 60px;
            height: 108px;
            background: #1D81FC;
            box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.5), 2px 2px 12px 4px rgba(230,230,230,0.5);
            border-radius: 8px;
            vertical-align: middle;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            padding: 10px 24px 10px 10px;
            box-sizing: border-box;
            line-height: 22px;
        }
        .right {
            display: inline-block;
            width: 225px;
            height: 130px;
            background: #FFFFFF;
            box-shadow: 2px 2px 12px 4px rgba(217,217,217,0.5);
            border-radius: 6px;
            vertical-align: middle;
            position: relative;
            left: -12px;
            font-size: 12px;
            color: #999;
            font-weight: 500;
            text-align: left;
            padding: 22px 20px 20px 29px;
            box-sizing: border-box;
        }
        .right::before, .right::after {
            display: block;
            content: '';
            width: 27px;
            height: 12px;
            background: #1D81FC;
            border-radius: 11px;
            position: absolute;
            left: -17px;
        }
        .right::before {
            top: 22px;
        }
        .right::after {
            bottom: 22px;
        }
    }

    .modal-wrap {
        width: 440px;
        height: 350px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        box-shadow: 0px 2px 11px 7px rgba(164,164,164,0.5);
        border-radius: 8px;
        text-align: center;
        input {
            border: 2px solid #1D81FC;
            border-radius: 8px;
            outline: none;
            background-color: transparent;
            // caretColor: "#47b34f",
            width: 279px;
            height: 47px;
            padding: 0 24px;
            font-size: 18px;
            box-sizing: border-box;
            color: #333333;
            margin-top: 23px;
        }
        .inputWrap {
            margin-top: 26px;
        }
        .modal-title {
            font-size: 20px;
            height: 30px;
            line-height: 30px;
            color: #121212;
            font-weight: bold;
            margin-top: 20px;
        }
        .modal-desc {
            font-size: 14px;
            height: 16px;
            line-height: 16px;
            color: #1579F4;
            font-weight: bold;
            margin-top: 16px;
        }
        .modal-phone-tip{
          font-size: 14px;
          height: 20px;
          line-height: 20px;
          color: #999999;
          font-weight: 500;
          margin-top: 5px;
        }
        .modal-bottom {
            margin-top: 3px;
            text-align: center;
            div {
                width: 200px;
                height: 40px;
                background: linear-gradient(270deg, #1D81FC 0%, #5FB1FF 100%);
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                margin: 0 auto;
                line-height: 40px;
                cursor: pointer;
            }
            .modal-phone {
                font-size: 14px;
                color: #999999;
                font-weight: 500;
                margin-top: 5px;
            }
        }
        .close {
            width: 40px;
            height: 40px;
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            img {
                display: block;
                width: 40px;
                height: 40px;
            }
        }
    }
}


.operator-top-image-ruzhu{
  width: 100%;
  &.isMobile{
    .operator-top-image-ruzhu-box{
      .provider-tab{
        position: unset;
        height: 44px;
        .provider-tab-box{
          width: 100%;
          padding: 0 16px;
        }
        .provider-tab-item{
          color: #666666;
          font-size: 16px;
          font-weight: 400;
          line-height: 44px;
          margin-right: 24px;
          .provider-tab-item-line{
            width: 60px;
            height: 3px;
            background: #08B243;
            position: absolute;
            bottom: 0;
            margin: 0 auto;
            left: 50%;
            margin-left: -30px;
            display: none;
          }
         
        }
      }
    }
    .ruzhu-content{
      width: 100%;
      padding: 0 16px;

      .ruzhu-content-title{
        color: #333333;
        font-size: 22px;
        margin-top: 32px;
        font-weight: 500;
        text-align: center;
      }

      .ruzhu-content-desc{
        color: #666666;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 7px;
        text-align: center;
        margin-bottom: 25px;
      }
    }
    .ruzhu-content2{
      padding: 0;
    }
    .ruzhu-content-box{
      padding: 0 16px;
    }
    .submit-form-box{
      background: #fff;
      padding: 0 16px;
      width: 100%;
      .submit-btn{
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 64px;
        width: 100%;

      }
      .from-1{
        width: 100%;
        margin: 0;
        .fieldType {
          display: block;
        }
      }
      .selectBox{
        width: 100%;
        margin-right: 0 !important;
        .selectOptions{
          width: 100%;
        }
      }
      .input-title,.fieldTitle{
        font-size: 14px;
        margin-bottom: 8px;
        margin-left: 0;
      }
      .input-wrap{
        width: 100%;
        
        .input-box{
          width: 100%!important;
        }
        &.textarea{
          .notice{
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: right;
            position: absolute;
            bottom: 8px;
            right:8px;
          }
        }
      }
      
    }
    
  }
  .operator-top-image-ruzhu-box{
    position: relative;

    .provider-tab{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -40px;
      height: 80px;
      // margin-top: -40px;
      .provider-tab-box{
        box-sizing: border-box;
        width: 1220px;
        margin: 0 auto;
        padding: 0 98px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
        background: #fff;
      }
      .provider-tab-item{
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        font-size: 24px;
        line-height: 80px;
        margin-right: 160px;
        cursor: pointer;
        position: relative;
        .provider-tab-item-line{
          width: 120px;
          height: 3px;
          background: #08B243;
          position: absolute;
          bottom: 0;
          margin: 0 auto;
          left: 50%;
          margin-left: -60px;
          display: none;
        }
        &.active{
          color:#08B243;
          font-weight: 600;
          .provider-tab-item-line{
            display: block;
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .selectTip {
    color: #FB4E44;
    font-size: 16px;
    height: 22.5px;
    font-weight: 400;
  }
  .zhanwei{
    width: 100%;
    height: 0;
    padding-bottom: 33.3%;
    background: #f6f6f6;
    display: block;
  }
  .buzhanwei{
    width: 100%;
    height: 100%;
    display: block;
  }
  .submit-form-box{
    box-sizing: border-box;
    width: 1036px;
    margin: 0 auto;
  }
  .ruzhu-content {
    box-sizing: border-box;
    width: 1220px;
    margin: 0 auto;
    padding-top: 80px;
    .ruzhu-content-title{
      color: #333333;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
      text-align: center;
    }
    .ruzhu-content-desc{
      color: #333333;
      font-size: 18px;
      font-weight: 400;
      margin-top: 20px;
      text-align: center;
      margin-bottom: 25px;
    }
  }
}

.tipShow{
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .topShowBg{
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.5;
  }
  .tipDialog{
    width: 800px;
    height: 616px;
    position: fixed;
    background-image: url(../images/tip_dialog_bg.png);
    background-size: cover;
    background-position: center;
    margin: auto;
    .tipTitle {
      position: absolute;
      top: 0;
      left: 21%;
      margin-top: 40px;
      width:448px;
      height: 80px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      word-wrap: break-word;
    }

    .tipdescribe {
      white-space: pre-wrap;
      width:680px;
      height: 330px;
      position: absolute;
      top: 120px;
      left: 6.25%;
      color: #666666;
    }

    .tipButton {
      width:272px;
      height: 60px;
      background-image: url(../images/tip_button_bg.png);
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 390px;
      left: 30%;
    }
  }
}
#fixed-div {
  touch-action: none;
}
